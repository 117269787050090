import { default as indexhMA87OLT2nMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/[type-or-wish-or-journey-slug]/[journey-slug]/index.vue?macro=true";
import { default as indexLIVHEKEH5DMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/[type-or-wish-or-journey-slug]/index.vue?macro=true";
import { default as indexMXYjf2zqBfMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/carnet-pratiques/[notebook-id]/index.vue?macro=true";
import { default as indexZRNoeeuYFEMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/index.vue?macro=true";
import { default as indexOh0hItD5TVMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/quand-partir-[destination-id]/index.vue?macro=true";
import { default as indexKxeCJ6xT0gMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/accomodations/[hotel-id]/index.vue?macro=true";
import { default as indexy6CkCzhPAoMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/carnet-pratiques/[notebook-id]/index.vue?macro=true";
import { default as indexPZ7gL51NYXMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/index.vue?macro=true";
import { default as index3J2MdrPU7NMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/quand-partir-[destination-id]/index.vue?macro=true";
import { default as indexmEKXi4GwWPMeta } from "/builds/cms4214216/frontend/pages/[geographic-zone-slug]/index.vue?macro=true";
import { default as indexJV3xVNYmTXMeta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/carnet-de-voyage/[article-category-id]/[article-slug]/index.vue?macro=true";
import { default as indexCko53XYFfEMeta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/carnet-de-voyage/[article-category-id]/index.vue?macro=true";
import { default as indexdvNe4Ybs8mMeta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/carnet-de-voyage/index.vue?macro=true";
import { default as indexROxkGiGxzFMeta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/catalogues/[article-slug]/index.vue?macro=true";
import { default as indexrSH4TRepf9Meta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/catalogues/index.vue?macro=true";
import { default as indexWApDrE1fBfMeta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/evenement-culturel/[event-category-id]/[event-slug]/index.vue?macro=true";
import { default as index1kzveP7cReMeta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/evenement-culturel/[event-category-id]/[event-slug]/inscription-evenement/index.vue?macro=true";
import { default as indexOaoKNEGTO5Meta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/evenement-culturel/[event-category-id]/index.vue?macro=true";
import { default as indexR2JP9AXt9fMeta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/evenement-culturel/index.vue?macro=true";
import { default as indexKNnJxttvQHMeta } from "/builds/cms4214216/frontend/pages/autour-du-voyage/index.vue?macro=true";
import { default as indexf9U65uqv4WMeta } from "/builds/cms4214216/frontend/pages/contrat-de-vente/index.vue?macro=true";
import { default as devis_45sur_45mesure1QEOoLsDPSMeta } from "/builds/cms4214216/frontend/pages/devis-sur-mesure.vue?macro=true";
import { default as indexzN9HfpynLFMeta } from "/builds/cms4214216/frontend/pages/index.vue?macro=true";
import { default as indexD5sx5XUs86Meta } from "/builds/cms4214216/frontend/pages/inspiration-voyage/[category-id]/index.vue?macro=true";
import { default as indexFIi79uN1UOMeta } from "/builds/cms4214216/frontend/pages/inspiration-voyage/index.vue?macro=true";
import { default as indexi8rd7aOlOPMeta } from "/builds/cms4214216/frontend/pages/newsletter/index.vue?macro=true";
import { default as indexWckQ8dpjIZMeta } from "/builds/cms4214216/frontend/pages/nos-conseillers/[expert-id]/index.vue?macro=true";
import { default as indexpxNwpop9fBMeta } from "/builds/cms4214216/frontend/pages/nos-conseillers/index.vue?macro=true";
import { default as indexFFZlMuRT0WMeta } from "/builds/cms4214216/frontend/pages/notre-engagement-responsable/index.vue?macro=true";
import { default as indexczigO7SBRpMeta } from "/builds/cms4214216/frontend/pages/notre-valeur-ajoutee/index.vue?macro=true";
import { default as indexDkSEE4LBKEMeta } from "/builds/cms4214216/frontend/pages/nous-rejoindre/index.vue?macro=true";
import { default as indexSektBD8iqEMeta } from "/builds/cms4214216/frontend/pages/ou-et-quand-partir/[period-id]/index.vue?macro=true";
import { default as indexBNrIjzxpR2Meta } from "/builds/cms4214216/frontend/pages/ou-et-quand-partir/index.vue?macro=true";
import { default as _91journey_45slug_93FuyGSHEOTiMeta } from "/builds/cms4214216/frontend/pages/pdf/[journey-slug].vue?macro=true";
import { default as contrat_45de_45venteER7CqwuLNvMeta } from "/builds/cms4214216/frontend/pages/pdf/contrat-de-vente.vue?macro=true";
import { default as _91opportunity_45id_93Y4YLGKl4QRMeta } from "/builds/cms4214216/frontend/pages/pdf/proposition/[opportunity-id].vue?macro=true";
import { default as indexrqNgwUqa60Meta } from "/builds/cms4214216/frontend/pages/preview/[journey-code]/index.vue?macro=true";
import { default as indexZ0vKA7D7wrMeta } from "/builds/cms4214216/frontend/pages/proposition/index.vue?macro=true";
import { default as index3fQJNzqY2iMeta } from "/builds/cms4214216/frontend/pages/qui-sommes-nous/index.vue?macro=true";
import { default as indexi1KifIfVEOMeta } from "/builds/cms4214216/frontend/pages/revue-de-presse/index.vue?macro=true";
import { default as testUAOB39F8I0Meta } from "/builds/cms4214216/frontend/pages/test.vue?macro=true";
import { default as indexnHcogCboFZMeta } from "/builds/cms4214216/frontend/pages/tous-les-itineraires/index.vue?macro=true";
import { default as indexwqykHbYzaxMeta } from "/builds/cms4214216/frontend/pages/voyage-[country-slug]/index.vue?macro=true";
import { default as index4u5wawvfzDMeta } from "/builds/cms4214216/frontend/pages/voyages-groupe/index.vue?macro=true";
export default [
  {
    name: indexhMA87OLT2nMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug-typeorwishorjourneyslug-journeyslug",
    path: indexhMA87OLT2nMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()/:typeorwishorjourneyslug()/:journeyslug()",
    meta: indexhMA87OLT2nMeta || {},
    alias: indexhMA87OLT2nMeta?.alias || [],
    redirect: indexhMA87OLT2nMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/[type-or-wish-or-journey-slug]/[journey-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLIVHEKEH5DMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug-typeorwishorjourneyslug",
    path: indexLIVHEKEH5DMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()/:typeorwishorjourneyslug()",
    meta: indexLIVHEKEH5DMeta || {},
    alias: indexLIVHEKEH5DMeta?.alias || [],
    redirect: indexLIVHEKEH5DMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/[type-or-wish-or-journey-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMXYjf2zqBfMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug-carnet-pratiques-notebookid",
    path: indexMXYjf2zqBfMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()/carnet-pratiques/:notebookid()",
    meta: indexMXYjf2zqBfMeta || {},
    alias: indexMXYjf2zqBfMeta?.alias || [],
    redirect: indexMXYjf2zqBfMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/carnet-pratiques/[notebook-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZRNoeeuYFEMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug",
    path: indexZRNoeeuYFEMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()",
    meta: indexZRNoeeuYFEMeta || {},
    alias: indexZRNoeeuYFEMeta?.alias || [],
    redirect: indexZRNoeeuYFEMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexOh0hItD5TVMeta?.name ?? "geographiczoneslug-countryslug-stopovertownortypeorwishslug-quand-partir-destinationid",
    path: indexOh0hItD5TVMeta?.path ?? "/:geographiczoneslug()/:countryslug()/:stopovertownortypeorwishslug()/quand-partir-:destinationid()",
    meta: indexOh0hItD5TVMeta || {},
    alias: indexOh0hItD5TVMeta?.alias || [],
    redirect: indexOh0hItD5TVMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/[stopover-town-or-type-or-wish-slug]/quand-partir-[destination-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxeCJ6xT0gMeta?.name ?? "geographiczoneslug-countryslug-accomodations-hotelid",
    path: indexKxeCJ6xT0gMeta?.path ?? "/:geographiczoneslug()/:countryslug()/accomodations/:hotelid()",
    meta: indexKxeCJ6xT0gMeta || {},
    alias: indexKxeCJ6xT0gMeta?.alias || [],
    redirect: indexKxeCJ6xT0gMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/accomodations/[hotel-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexy6CkCzhPAoMeta?.name ?? "geographiczoneslug-countryslug-carnet-pratiques-notebookid",
    path: indexy6CkCzhPAoMeta?.path ?? "/:geographiczoneslug()/:countryslug()/carnet-pratiques/:notebookid()",
    meta: indexy6CkCzhPAoMeta || {},
    alias: indexy6CkCzhPAoMeta?.alias || [],
    redirect: indexy6CkCzhPAoMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/carnet-pratiques/[notebook-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPZ7gL51NYXMeta?.name ?? "geographiczoneslug-countryslug",
    path: indexPZ7gL51NYXMeta?.path ?? "/:geographiczoneslug()/:countryslug()",
    meta: indexPZ7gL51NYXMeta || {},
    alias: indexPZ7gL51NYXMeta?.alias || [],
    redirect: indexPZ7gL51NYXMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index3J2MdrPU7NMeta?.name ?? "geographiczoneslug-countryslug-quand-partir-destinationid",
    path: index3J2MdrPU7NMeta?.path ?? "/:geographiczoneslug()/:countryslug()/quand-partir-:destinationid()",
    meta: index3J2MdrPU7NMeta || {},
    alias: index3J2MdrPU7NMeta?.alias || [],
    redirect: index3J2MdrPU7NMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/[country-slug]/quand-partir-[destination-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEKXi4GwWPMeta?.name ?? "geographiczoneslug",
    path: indexmEKXi4GwWPMeta?.path ?? "/:geographiczoneslug()",
    meta: indexmEKXi4GwWPMeta || {},
    alias: indexmEKXi4GwWPMeta?.alias || [],
    redirect: indexmEKXi4GwWPMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/[geographic-zone-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJV3xVNYmTXMeta?.name ?? "autour-du-voyage-carnet-de-voyage-articlecategoryid-articleslug",
    path: indexJV3xVNYmTXMeta?.path ?? "/autour-du-voyage/carnet-de-voyage/:articlecategoryid()/:articleslug()",
    meta: indexJV3xVNYmTXMeta || {},
    alias: indexJV3xVNYmTXMeta?.alias || [],
    redirect: indexJV3xVNYmTXMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/carnet-de-voyage/[article-category-id]/[article-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexCko53XYFfEMeta?.name ?? "autour-du-voyage-carnet-de-voyage-articlecategoryid",
    path: indexCko53XYFfEMeta?.path ?? "/autour-du-voyage/carnet-de-voyage/:articlecategoryid()",
    meta: indexCko53XYFfEMeta || {},
    alias: indexCko53XYFfEMeta?.alias || [],
    redirect: indexCko53XYFfEMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/carnet-de-voyage/[article-category-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdvNe4Ybs8mMeta?.name ?? "autour-du-voyage-carnet-de-voyage",
    path: indexdvNe4Ybs8mMeta?.path ?? "/autour-du-voyage/carnet-de-voyage",
    meta: indexdvNe4Ybs8mMeta || {},
    alias: indexdvNe4Ybs8mMeta?.alias || [],
    redirect: indexdvNe4Ybs8mMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/carnet-de-voyage/index.vue").then(m => m.default || m)
  },
  {
    name: indexROxkGiGxzFMeta?.name ?? "autour-du-voyage-catalogues-articleslug",
    path: indexROxkGiGxzFMeta?.path ?? "/autour-du-voyage/catalogues/:articleslug()",
    meta: indexROxkGiGxzFMeta || {},
    alias: indexROxkGiGxzFMeta?.alias || [],
    redirect: indexROxkGiGxzFMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/catalogues/[article-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrSH4TRepf9Meta?.name ?? "autour-du-voyage-catalogues",
    path: indexrSH4TRepf9Meta?.path ?? "/autour-du-voyage/catalogues",
    meta: indexrSH4TRepf9Meta || {},
    alias: indexrSH4TRepf9Meta?.alias || [],
    redirect: indexrSH4TRepf9Meta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/catalogues/index.vue").then(m => m.default || m)
  },
  {
    name: indexWApDrE1fBfMeta?.name ?? "autour-du-voyage-evenement-culturel-eventcategoryid-eventslug",
    path: indexWApDrE1fBfMeta?.path ?? "/autour-du-voyage/evenement-culturel/:eventcategoryid()/:eventslug()",
    meta: indexWApDrE1fBfMeta || {},
    alias: indexWApDrE1fBfMeta?.alias || [],
    redirect: indexWApDrE1fBfMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/evenement-culturel/[event-category-id]/[event-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index1kzveP7cReMeta?.name ?? "autour-du-voyage-evenement-culturel-eventcategoryid-eventslug-inscription-evenement",
    path: index1kzveP7cReMeta?.path ?? "/autour-du-voyage/evenement-culturel/:eventcategoryid()/:eventslug()/inscription-evenement",
    meta: index1kzveP7cReMeta || {},
    alias: index1kzveP7cReMeta?.alias || [],
    redirect: index1kzveP7cReMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/evenement-culturel/[event-category-id]/[event-slug]/inscription-evenement/index.vue").then(m => m.default || m)
  },
  {
    name: indexOaoKNEGTO5Meta?.name ?? "autour-du-voyage-evenement-culturel-eventcategoryid",
    path: indexOaoKNEGTO5Meta?.path ?? "/autour-du-voyage/evenement-culturel/:eventcategoryid()",
    meta: indexOaoKNEGTO5Meta || {},
    alias: indexOaoKNEGTO5Meta?.alias || [],
    redirect: indexOaoKNEGTO5Meta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/evenement-culturel/[event-category-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexR2JP9AXt9fMeta?.name ?? "autour-du-voyage-evenement-culturel",
    path: indexR2JP9AXt9fMeta?.path ?? "/autour-du-voyage/evenement-culturel",
    meta: indexR2JP9AXt9fMeta || {},
    alias: indexR2JP9AXt9fMeta?.alias || [],
    redirect: indexR2JP9AXt9fMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/evenement-culturel/index.vue").then(m => m.default || m)
  },
  {
    name: indexKNnJxttvQHMeta?.name ?? "autour-du-voyage",
    path: indexKNnJxttvQHMeta?.path ?? "/autour-du-voyage",
    meta: indexKNnJxttvQHMeta || {},
    alias: indexKNnJxttvQHMeta?.alias || [],
    redirect: indexKNnJxttvQHMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/autour-du-voyage/index.vue").then(m => m.default || m)
  },
  {
    name: indexf9U65uqv4WMeta?.name ?? "contrat-de-vente",
    path: indexf9U65uqv4WMeta?.path ?? "/contrat-de-vente",
    meta: indexf9U65uqv4WMeta || {},
    alias: indexf9U65uqv4WMeta?.alias || [],
    redirect: indexf9U65uqv4WMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/contrat-de-vente/index.vue").then(m => m.default || m)
  },
  {
    name: devis_45sur_45mesure1QEOoLsDPSMeta?.name ?? "devis-sur-mesure",
    path: devis_45sur_45mesure1QEOoLsDPSMeta?.path ?? "/devis-sur-mesure",
    meta: devis_45sur_45mesure1QEOoLsDPSMeta || {},
    alias: devis_45sur_45mesure1QEOoLsDPSMeta?.alias || [],
    redirect: devis_45sur_45mesure1QEOoLsDPSMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/devis-sur-mesure.vue").then(m => m.default || m)
  },
  {
    name: indexzN9HfpynLFMeta?.name ?? "index",
    path: indexzN9HfpynLFMeta?.path ?? "/",
    meta: indexzN9HfpynLFMeta || {},
    alias: indexzN9HfpynLFMeta?.alias || [],
    redirect: indexzN9HfpynLFMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexD5sx5XUs86Meta?.name ?? "inspiration-voyage-categoryid",
    path: indexD5sx5XUs86Meta?.path ?? "/inspiration-voyage/:categoryid()",
    meta: indexD5sx5XUs86Meta || {},
    alias: indexD5sx5XUs86Meta?.alias || [],
    redirect: indexD5sx5XUs86Meta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/inspiration-voyage/[category-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFIi79uN1UOMeta?.name ?? "inspiration-voyage",
    path: indexFIi79uN1UOMeta?.path ?? "/inspiration-voyage",
    meta: indexFIi79uN1UOMeta || {},
    alias: indexFIi79uN1UOMeta?.alias || [],
    redirect: indexFIi79uN1UOMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/inspiration-voyage/index.vue").then(m => m.default || m)
  },
  {
    name: indexi8rd7aOlOPMeta?.name ?? "newsletter",
    path: indexi8rd7aOlOPMeta?.path ?? "/newsletter",
    meta: indexi8rd7aOlOPMeta || {},
    alias: indexi8rd7aOlOPMeta?.alias || [],
    redirect: indexi8rd7aOlOPMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: indexWckQ8dpjIZMeta?.name ?? "nos-conseillers-expertid",
    path: indexWckQ8dpjIZMeta?.path ?? "/nos-conseillers/:expertid()",
    meta: indexWckQ8dpjIZMeta || {},
    alias: indexWckQ8dpjIZMeta?.alias || [],
    redirect: indexWckQ8dpjIZMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/nos-conseillers/[expert-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpxNwpop9fBMeta?.name ?? "nos-conseillers",
    path: indexpxNwpop9fBMeta?.path ?? "/nos-conseillers",
    meta: indexpxNwpop9fBMeta || {},
    alias: indexpxNwpop9fBMeta?.alias || [],
    redirect: indexpxNwpop9fBMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/nos-conseillers/index.vue").then(m => m.default || m)
  },
  {
    name: indexFFZlMuRT0WMeta?.name ?? "notre-engagement-responsable",
    path: indexFFZlMuRT0WMeta?.path ?? "/notre-engagement-responsable",
    meta: indexFFZlMuRT0WMeta || {},
    alias: indexFFZlMuRT0WMeta?.alias || [],
    redirect: indexFFZlMuRT0WMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/notre-engagement-responsable/index.vue").then(m => m.default || m)
  },
  {
    name: indexczigO7SBRpMeta?.name ?? "notre-valeur-ajoutee",
    path: indexczigO7SBRpMeta?.path ?? "/notre-valeur-ajoutee",
    meta: indexczigO7SBRpMeta || {},
    alias: indexczigO7SBRpMeta?.alias || [],
    redirect: indexczigO7SBRpMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/notre-valeur-ajoutee/index.vue").then(m => m.default || m)
  },
  {
    name: indexDkSEE4LBKEMeta?.name ?? "nous-rejoindre",
    path: indexDkSEE4LBKEMeta?.path ?? "/nous-rejoindre",
    meta: indexDkSEE4LBKEMeta || {},
    alias: indexDkSEE4LBKEMeta?.alias || [],
    redirect: indexDkSEE4LBKEMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/nous-rejoindre/index.vue").then(m => m.default || m)
  },
  {
    name: indexSektBD8iqEMeta?.name ?? "ou-et-quand-partir-periodid",
    path: indexSektBD8iqEMeta?.path ?? "/ou-et-quand-partir/:periodid()",
    meta: indexSektBD8iqEMeta || {},
    alias: indexSektBD8iqEMeta?.alias || [],
    redirect: indexSektBD8iqEMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/ou-et-quand-partir/[period-id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBNrIjzxpR2Meta?.name ?? "ou-et-quand-partir",
    path: indexBNrIjzxpR2Meta?.path ?? "/ou-et-quand-partir",
    meta: indexBNrIjzxpR2Meta || {},
    alias: indexBNrIjzxpR2Meta?.alias || [],
    redirect: indexBNrIjzxpR2Meta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/ou-et-quand-partir/index.vue").then(m => m.default || m)
  },
  {
    name: _91journey_45slug_93FuyGSHEOTiMeta?.name ?? "pdf-journeyslug",
    path: _91journey_45slug_93FuyGSHEOTiMeta?.path ?? "/pdf/:journeyslug()",
    meta: _91journey_45slug_93FuyGSHEOTiMeta || {},
    alias: _91journey_45slug_93FuyGSHEOTiMeta?.alias || [],
    redirect: _91journey_45slug_93FuyGSHEOTiMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/pdf/[journey-slug].vue").then(m => m.default || m)
  },
  {
    name: contrat_45de_45venteER7CqwuLNvMeta?.name ?? "pdf-contrat-de-vente",
    path: contrat_45de_45venteER7CqwuLNvMeta?.path ?? "/pdf/contrat-de-vente",
    meta: contrat_45de_45venteER7CqwuLNvMeta || {},
    alias: contrat_45de_45venteER7CqwuLNvMeta?.alias || [],
    redirect: contrat_45de_45venteER7CqwuLNvMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/pdf/contrat-de-vente.vue").then(m => m.default || m)
  },
  {
    name: _91opportunity_45id_93Y4YLGKl4QRMeta?.name ?? "pdf-proposition-opportunityid",
    path: _91opportunity_45id_93Y4YLGKl4QRMeta?.path ?? "/pdf/proposition/:opportunityid()",
    meta: _91opportunity_45id_93Y4YLGKl4QRMeta || {},
    alias: _91opportunity_45id_93Y4YLGKl4QRMeta?.alias || [],
    redirect: _91opportunity_45id_93Y4YLGKl4QRMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/pdf/proposition/[opportunity-id].vue").then(m => m.default || m)
  },
  {
    name: indexrqNgwUqa60Meta?.name ?? "preview-journeycode",
    path: indexrqNgwUqa60Meta?.path ?? "/preview/:journeycode()",
    meta: indexrqNgwUqa60Meta || {},
    alias: indexrqNgwUqa60Meta?.alias || [],
    redirect: indexrqNgwUqa60Meta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/preview/[journey-code]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ0vKA7D7wrMeta?.name ?? "proposition",
    path: indexZ0vKA7D7wrMeta?.path ?? "/proposition",
    meta: indexZ0vKA7D7wrMeta || {},
    alias: indexZ0vKA7D7wrMeta?.alias || [],
    redirect: indexZ0vKA7D7wrMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/proposition/index.vue").then(m => m.default || m)
  },
  {
    name: index3fQJNzqY2iMeta?.name ?? "qui-sommes-nous",
    path: index3fQJNzqY2iMeta?.path ?? "/qui-sommes-nous",
    meta: index3fQJNzqY2iMeta || {},
    alias: index3fQJNzqY2iMeta?.alias || [],
    redirect: index3fQJNzqY2iMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/qui-sommes-nous/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1KifIfVEOMeta?.name ?? "revue-de-presse",
    path: indexi1KifIfVEOMeta?.path ?? "/revue-de-presse",
    meta: indexi1KifIfVEOMeta || {},
    alias: indexi1KifIfVEOMeta?.alias || [],
    redirect: indexi1KifIfVEOMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/revue-de-presse/index.vue").then(m => m.default || m)
  },
  {
    name: testUAOB39F8I0Meta?.name ?? "test",
    path: testUAOB39F8I0Meta?.path ?? "/test",
    meta: testUAOB39F8I0Meta || {},
    alias: testUAOB39F8I0Meta?.alias || [],
    redirect: testUAOB39F8I0Meta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/test.vue").then(m => m.default || m)
  },
  {
    name: indexnHcogCboFZMeta?.name ?? "tous-les-itineraires",
    path: indexnHcogCboFZMeta?.path ?? "/tous-les-itineraires",
    meta: indexnHcogCboFZMeta || {},
    alias: indexnHcogCboFZMeta?.alias || [],
    redirect: indexnHcogCboFZMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/tous-les-itineraires/index.vue").then(m => m.default || m)
  },
  {
    name: indexwqykHbYzaxMeta?.name ?? "voyage-countryslug",
    path: indexwqykHbYzaxMeta?.path ?? "/voyage-:countryslug()",
    meta: indexwqykHbYzaxMeta || {},
    alias: indexwqykHbYzaxMeta?.alias || [],
    redirect: indexwqykHbYzaxMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/voyage-[country-slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index4u5wawvfzDMeta?.name ?? "voyages-groupe",
    path: index4u5wawvfzDMeta?.path ?? "/voyages-groupe",
    meta: index4u5wawvfzDMeta || {},
    alias: index4u5wawvfzDMeta?.alias || [],
    redirect: index4u5wawvfzDMeta?.redirect,
    component: () => import("/builds/cms4214216/frontend/pages/voyages-groupe/index.vue").then(m => m.default || m)
  }
]